<template>
  <div id="CustomerDZMotor">
    <!-- CustomerDZMotor头部内容 -->
    <div class="CustomerDZMotorHeader">
      <!-- 头部组件 -->
      <Header />
    </div>
    <!-- CustomerDZMotor中间内容 -->
    <div class="CustomerDZMotorCenter">
      <!-- 客户介绍 -->
      <div class="contentOne">
        <div class="titleOne">客户介绍</div>
        <div class="conInfo">
          <img src="~assets/img/CustomerDZMotor/公司@2x.png" />
          <div class="infoOne">
            &#160;&#160;&#160;&#160;&#160;浙江东政电机有限公司地处浙江省东阳市白云商贸园区，公司拥有大量先进的加工中心、数控车床、数控全自动滚齿机、精密滚齿机、装配流水线、自动转子生产线等设备组成的现代化生产车间，是集研发、生产制造、销售为一体的国家高新技术企业，在行业中具有相当的影响。<br /><br />
            &#160;&#160;&#160;&#160;&#160;浙江东政电机有限公司主要产品有微型交、直流减速电机、行星减速电机及微型
            减速器等控制电机，在国内外享有盛誉。产品约90%出口到30多个国家和地区。公司建立了完善的品质保证体系，已获得ISO9001质量管理体系认证证书、ISO14001
            环境管理体系认证证书、OHSAS18001职业健康安全管理体系认证证书，交、直流减
            速电机通过美国UL、国家CCC、CE和Reach认证。
          </div>
        </div>
      </div>
      <!-- 项目价值 -->
      <div class="contentTwo">
        <div class="titleTwo">项目价值</div>
        <div class="titleT">
          2021年5月，我司与浙江东政电机有限公司签署设备管理上云项目合同，通过对精工车间、装配车间的加工机床、装配生产线等设备进行数据采集与联网。针对车间的生
          产设备提供设备建模、设备管理、状态监控、数据采集及分析，有效支持设备的可视化管理、提升设备生产利用率。2021年8月，项目进入试运行。
        </div>

        <ul class="conBot">
         <li v-for="(item,index) in conBots" :key="index">
<div class="conBotTit">
  <div class="icons"></div> {{item.tit}}</div>
  <div class="conBotContent">{{item.text}}</div>
         </li>
        </ul>
      </div>
      <!-- 项目概况 -->
      <div class="contentThree">
        <div class="titleThree">项目概况</div>
        <ul class="imgContent">
          <li v-for="(item, index) in imgContents" :key="index">
            <img :src="item.Imgs" />
            <div class="imgBottom">{{ item.textPro }}</div>
          </li>
        </ul>
      </div>
    </div>
    <!-- 底部内容 -->
    <Footer />
    <!-- 回到顶部 -->
    <el-backtop
      ><img
        src="~assets/img/CustomerDZMotor/icon-一键置顶@2x.png"
        alt="icon-回到顶部@2x.png"
    /></el-backtop>
  </div>
</template>
<script>
import Header from "components/Header/Header";
import Footer from "components/Footer/Footer";
export default {
  name: "CustomerDZMotor",
  data() {
    return {
      imgContents: [
        {
          Imgs: require("assets/img/CustomerDZMotor/领导调研东政数字化项目.jpg"),
          textPro: "2021年8月18日 东阳市经信局陈其宪局长调研东政数字化项目"
        },
         {
          Imgs: require("assets/img/CustomerDZMotor/数控车床车间@2x.png"),
          textPro: "数控车床车间"
        },
         {
          Imgs: require("assets/img/CustomerDZMotor/全自动生产流水线@2x.png"),
          textPro: "全自动生产流水线"
        },
         {
          Imgs: require("assets/img/CustomerDZMotor/信息系统监控平台@2x.png"),
          textPro: "信息系统监控平台"
        },
         {
          Imgs: require("assets/img/CustomerDZMotor/设备监控@2x.png"),
          textPro: "设备监控页面"
        },
      ],
      conBots: [
        {
          tit: "- 设备台账",
          text: "设备档案设备知识库维保知识库"
        },
     {
          tit: "- 备件管理",
          text: "备件安全库存管理备件出入库管理备件费用分析"
        },
         {
          tit: "- 设备保养",
          text: "保养计划保养作业保养逾期通知"
        },
          {
          tit: "- 设备点检",
          text: "点检计划点检作业逾期通知"
        },
          {
          tit: "- 维修管理",
          text: "报修、派单、维修、评价维修&超时升级通知管理各类维修报表分析"
        },
      ]
    };
  },
  components: {
    Header,
    Footer
  }
};
</script>
<style lang="less" scope>
#CustomerDZMotor {
   width: 1583px;
  background-color: #f8f9fb;
  margin: 0 auto;
  // CusCustomerDZMotor头部样式
  .CustomerDZMotorHeader {
    width: 1583px;
    height: 620px;
    background: url("~assets/img/CustomerDZMotor/banner@2x.png")
      no-repeat;
    background-size: 1583px 620px;
    background-position: center;
    margin: 0 auto;
  }
  // CustomerDZMotor中间内容样式
  .CustomerDZMotorCenter {
    width: 1220px;
    margin: 80px auto 101px;
    // 客户介绍
    .contentOne {
      .titleOne {
        font-size: 20px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        line-height: 28px;
        color: #333333;
      }
      .conInfo {
        margin-top: 30px;
        display: flex;
        img {
          width: 560px;
          height: 343px;
        }
        .infoOne {
          width: 590px;
          height: 157px;
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          line-height: 28px;
          color: rgba(51, 51, 51, 0.85);
          margin: 33px 0 0 40px;
        }
      }
    }
    // 项目价值
    .contentTwo {
      margin-top: 60px;
      .titleTwo {
        font-size: 20px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        line-height: 28px;
        color: #333333;
      }
      .titleT {
        width: 1200px;
        height: 72px;
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        line-height: 28px;
        color: rgba(51, 51, 51, 0.85);
        margin-top: 30px;
      }
      .conBot {
        margin-top: 30px;
        padding: 0;
        display: flex;
        justify-content: space-between;
        width: 1220px;
        height: 146px;
li{
  background-color: #fff;
  width: 215px;
  height: 146px;
  &:nth-child(2){
    .conBotContent{
      width: 113px;
    }
  }
   &:nth-child(3){
    .conBotContent{
      width: 85px;
    }
  }
   &:nth-child(4){
    .conBotContent{
      width: 56px;
    }
  }
   &:nth-child(5){
    .conBotContent{
      width: 155px;
    }
  }
.conBotTit{
  display: flex;
font-size: 16px;
font-family: Source Han Sans CN;
font-weight: 500;
color: #1E90FF;
margin-top: 20px;
  .icons{
width: 6px;
height: 20px;
background: #1E90FF;
border-radius: 0px 3px 3px 0px;
margin-right: 30px;

}


}
.conBotContent{
 width: 71px;
height: 70px;
font-size: 14px;
font-family: Source Han Sans CN;
font-weight: 400;
line-height: 28px;
color: #333333;
margin: 6px 0 0 30px;

  }
}
      }
    }
    // 项目概况
    .contentThree {
      margin-top: 60px;
      .titleThree {
        font-size: 20px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #333333;
      }
      .imgContent {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 30px;
        padding: 0;
        li {
          margin-bottom: 30px;
          background-color: #fff;
          &:nth-child(3) {
            width: 330px;
            height: 324px;
            img {
              width: 344px;
              height:280px;
            }
          }
          &:nth-child(2){
            width: 365px;
            height: 324px;
            img{
            width: 365px;
            height: 279px;
            }
          }
          &:nth-child(1) {
            width: 449px;
             height: 324px;
            img {
              width: 449px;
              height: 280px;
            }
          }
          &:nth-child(4) {
            width:570px;
            height: 361px;
            img {
              width: 570px;
              height: 317px;
            }
          }
          &:nth-child(5) {
            width: 610px;
            height: 361px;
            img {
              width: 610px;
              height: 317px;
            }
          }
     

          .imgBottom {
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: rgba(51, 51, 51, 0.85);
            margin-top: 15px;
            text-align: center;
          }
        }
      }
    }
  }
}
</style>
<style lang="less">
.el-backtop {
  img {
    width: 30px;
    height: 30px;
  }
}
</style>
